import React from 'react';
import { NextApiRequest, NextApiResponse } from 'next';
import forceHttps from '../util/force-https';
import Page from '../components/Page/Page';

//@ts-ignore
export default function PageContainer(props) {
  if (props.page?.fields) {
    return (
      <Page
        {...props.page.fields}
        withNotification={props.withNotification}
        baseURL={props.baseURL}
        pathname={props.pathname}
        locale={props.locale}
      />
    );
  }
  return <div />;
}

export async function getServerSideProps({ req, res }: { req: NextApiRequest, res: NextApiResponse }) {
  forceHttps({ res, req });

  return {
    props: {},
  };
}







